













































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DevicesAction, DevicesActionType, DevicesState } from '@/store/modules/devices/types';
import { CircuitsGetter, CircuitsGetterType, CircuitsState } from '@/store/modules/circuits/types';
import { AppState } from '@/store/modules/app/types';
import { ScenariosGetter, ScenariosGetterType } from '@/store/modules/scenarios/types';
import { Models } from '@mtap-smartcity/lib-api';
import { isLamp } from '@/utils/type_check';
import {
  UserGetter, UserGetterType
} from '@/store/modules/admin/types';

const admin = namespace('admin');
const devices = namespace('devices');
const circuits = namespace('circuits');
const scenarios = namespace('scenarios');
const app = namespace('app');

@Component
export default class AssignTableLamps extends Vue {
  @app.State
  userActionStatus!: AppState['userActionStatus'];

  @devices.State
  devices!: DevicesState['devices']

  @circuits.State
  selectedCircuitID!: CircuitsState['selectedCircuitID']

  @circuits.State
  selectedCircuitUuid!: CircuitsState['selectedCircuitUuid']

  @circuits.Getter(CircuitsGetter.GetCircuit)
  getCircuit!: CircuitsGetterType['GET_CIRCUIT'];

  @scenarios.Getter(ScenariosGetter.GetScenario)
  getScenario!: ScenariosGetterType['GET_SCENARIO'];

  @devices.Action(DevicesAction.AddDeviceToCircuit)
  addDeviceToCircuit!: DevicesActionType['ADD_DEVICE_TO_CIRCUIT'];

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  get sortedLamps() {
    let result = this.devices.filter(isLamp).sort((a, b) => {
      if (a.object_id < b.object_id) return -1;
      if (a.object_id > b.object_id) return 1;
      return 0;
    });
    if (this.selectedCircuitID) {
      result = result.sort((a, b) => {
        if (a.circuit_id === this.selectedCircuitID && b.circuit_id !== this.selectedCircuitID) {
          return -1;
        }
        return 1;
      });
    }
    return result;
  }

  get permissionCheckWrite() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'circuits');
    return getPermissions.write;
  }

  circuitName(lamp: Models.Devices.Lamp) {
    const { circuit_id } = lamp;
    if (!circuit_id) {
      return '';
    }
    const { name } = this.getCircuit(circuit_id) ?? { name: '' };
    return name;
  }

  circuitColor(lamp: Models.Devices.Lamp) {
    const { circuit_id } = lamp;
    if (!circuit_id) return '';
    const { color } = this.getCircuit(circuit_id) ?? { color: '' };
    return color;
  }

  scenarioName(lamp: Models.Devices.Lamp) {
    const { scenario_id } = lamp;
    if (!scenario_id) {
      return '';
    }
    const { name } = this.getScenario(scenario_id) ?? { name: '' };
    return name;
  }

  belongsToSelectedCircuit(lamp: Models.Devices.Lamp) {
    return lamp.circuit_id
      ? lamp.circuit_id === this.selectedCircuitUuid
      : false;
  }

  addToCircuit(lamp: Models.Devices.Lamp, switchLoader: () => void) {
    switchLoader();
    const circuitUuid = this.belongsToSelectedCircuit(lamp) ? null : this.selectedCircuitUuid;
    this.addDeviceToCircuit({
      uuid: lamp.uuid as string,
      circuitId: circuitUuid,
      controllerType: lamp.controller_type,
    }).finally(() => {
      switchLoader();
    });
  }
}
