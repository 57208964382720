import { render, staticRenderFns } from "./AssignTableLamps.vue?vue&type=template&id=13535348&scoped=true&"
import script from "./AssignTableLamps.vue?vue&type=script&lang=ts&"
export * from "./AssignTableLamps.vue?vue&type=script&lang=ts&"
import style0 from "./AssignTableLamps.vue?vue&type=style&index=0&id=13535348&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13535348",
  null
  
)

export default component.exports